.Poster {
  width: 100vw;
  font-size: 4rem;
}
.main-contact-us {
  /* display: flex;
  flex-direction: row; */
  flex-wrap: wrap;
  
}


h1 {
  font-size: 3rem;
  /* color: #00024B; */
  /* margin: 20px 20px; */
}

.contact-form {
  max-width: 400px;

  /* margin: 50px auto; */
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-extra {
  display: flex;
  flex-direction: row;
}
.form-field {
  /* position: relative; */
  margin-bottom: 20px;
  width: 400px;
  margin-top: 50px;
}

.form-field input[type="text"],
.form-field input[type="email"],
.form-field textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 3px solid #00024b;
  outline: none;
}

.address {
  /* margin: 20px 50px; */
  color: #00024b;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.address > * {
  /* margin-top: 20px; */
}

.form-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  /* background-color: #00024B; */
}

button[type="submit"] {
  display: block;
  /* margin: 100px 200px; */
  padding: 10px 65px;
  /* background-color: #00024b; */
  color: white;
  border: none;
  cursor: pointer;
}
@media only screen and (max-width: 900px) {
  /*Tablets [601px -> 1200px]*/
  .form-extra {
    flex-direction: column;
  }
  .form-field {
    /* position: relative; */
    margin-bottom: 20px;
    width: 300px;
    margin: 50px 50px;
  }
  .contact-form {
    max-width: 400px;
    /* margin: 10px auto; */
    /* padding: 10px 20px; */
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    /* align-items: start; */
    /* justify-content: center; */
  }
}
