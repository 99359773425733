.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Poppins;
  font-size: .9rem;
}
@media only screen and (max-width: 900px){
  /*Tablets [601px -> 1200px]*/
  /* *{
      align-items: center;
      justify-content: center;
  } */
  
}

.custom-toast-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* margin-top: 30vm; */
  /* height: 100vh; */
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
