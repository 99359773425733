.Experience {
    /* width: 70vw; */
    min-height: 50vh;
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    /* background-color: yellow; */


}

.experience-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:30px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
}



@media screen and (max-width: 600px) {
    .experience-grid {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        margin: 0px;
        padding: 0px;
        /* background-origin: content-box; */
        /* place-content: center;
        justify-content: center;
        align-items: center; */
    }
    .experience-card{
        width: 100%;
       
        /* margin-left: 70px; */
    }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
    .experience-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

.experience-card {
    /* position: relative; */
    /* margin: 20px 10px; */
    width: 300px;
    height: 400px;
    /* height: 50vh;
    background-color: wheat; */
    color: white;
    display: flex;
    justify-content: center;
    align-items: end;
}

.experience-card span {
    /* position: absolute; */

    /* bottom: 10px;
    left: 10px; */
}