@tailwind base;
@tailwind components;
@tailwind utilities;
*,::after,::before{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    zoom: 99.8%;
}
body{
    font-family: nunito;
}