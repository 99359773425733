.Activity{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}
.activities{
    /* width: 100vw; */
    /* flex-wrap: wrap; */
    /* display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    justify-content: center;
    align-items: center;

}
img{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.activity-card{
    border-radius: 5px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    width: 320px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 10px;
    /* background-color: white; */
    margin: 1rem 1rem;
    /* padding: 1rem; */
}
.activity-card .activity-image{
    width: 100%;
    height: 300px;
  
}
.activity-title{
    width: 300px; /* Adjust the width according to your layout */
  /* white-space: nowrap; */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  /* line-height: 20px; */
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.btn-book:hover{
    cursor: pointer;
}
.card-footer{
    width: 100%;
  
    display: flex;
    margin-top: 10px;
    /* gap: 50px; */
    justify-content: space-between;
}
@media only screen and (max-width: 900px) {
    /*Tablets [601px -> 1200px]*/
    .activities{
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    
    }
  }
  @media only screen and (max-width: 600px) {
    /*Big smartphones [426px -> 600px]*/

    .activities{
        grid-template-columns: repeat(1, 1fr);
    }
  }
  @media only screen and (max-width: 425px) {
    /*Small smartphones [325px -> 425px]*/
  }
  