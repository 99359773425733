.Destinaiton {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.destination-cards {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    place-items: center;
    width: 100%;
}

@media screen and (max-width: 600px) {
    .destination-cards {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (min-width: 600px) and (max-width: 990px) {
    .destination-cards {
        grid-template-columns: repeat(2, 1fr);
    }
}

.destination-card {
    width: 300px;
    height: 200px;
    /* background-color: blue; */
    margin: 30px;
    border-radius: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
}

.destn-btn {
    /* position: absolute; */

    right: 5rem;
}

@media only screen and (max-width: 900px) {
    .destn-btn {
        position: relative;
    }
}