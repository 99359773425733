.SearchHeader{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* width: 100vw; */
    /* margin-left: 11%; */
    margin-top: 20px; 
    padding: 5px 80px;
    width: 100%;
    /* flex-wrap: wrap; */
}
.text-header{
    font-size: 2rem;
    font-weight: bold;
    padding: 0px 0px;
    /* margin-left: 10px; */
}
@media screen and (max-width: 600px) {
    .SearchHeader {
        /* margin-left: -40px; */
        width: 100%;
    }
    .text-header {
        font-size: 1rem;
        width: 100%;
        /* margin-top: 0px; */
        /* margin-left: -78px; */
    }
}
.SearchHeader .tags {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    width: 100%;
    padding: 5px;
}
.SearchHeader .tags span{
    /* margin: 15px; */
    padding: 6px 2px;
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, .1);
}
