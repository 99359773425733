.Testimonial {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* z-index: -10px; */
}

.testimonoal-cards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
 

}

.testimonoal-card {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5%;
    padding: 5%;
    
}

.testimonoal-card .image {
    width: 200px;
    height: 200px;
    background-color: antiquewhite;
    border-radius: 50%;
}

@media only screen and (max-width: 900px) {

    /*Tablets [601px -> 1200px]*/
    .testimonoal-cards {
        /* flex-direction: column; */
        flex-wrap: wrap;
        

    }

}

@media only screen and (max-width: 600px) {
    /*Big smartphones [426px -> 600px]*/
}

@media only screen and (max-width: 425px) {
    /*Small smartphones [325px -> 425px]*/
}