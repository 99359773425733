.Profile {
  text-align: center;
  min-height: 120vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: relative; */
}
.profile-form-container {
  background-color: white;
  top: 45vh;
  z-index: 100;
  position: absolute;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  /* display: flex; */
  width: 80vw;
  height: 120vh;
  font-size: 1.2rem;
}
.booking-container {
  width: 90%;
}
.form {
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  width: 60%;
}
.bookingss{
  /* background-color: rgb(162, 74, 74); */
  box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
  /* background-color: rgba(0,0,0,0.1); */
  text-align: left;
  
  min-width: 380px;
  /* width: 100%; */
  /* box-shadow: 5px 15px 15px -3px rgba(0, 0, 0, 0.1); */
}
.bookings {
  background-color: rgb(242, 242, 242);
  box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.1);
  /* background-color: rgba(0,0,0,0.1); */
  text-align: left;
  
  min-width: 380px;
  /* width: 100%; */
  /* box-shadow: 5px 15px 15px -3px rgba(0, 0, 0, 0.1); */
}
.upcoming-booking {
  padding: 20px 40px;
  min-width: 100%;
  font-size: 1rem;
  max-height: 110rem;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.single-book {
  font-size: 0.7rem;
  display: flex;
  width: 100%;
  /* gap: 0px; */
}
.single-book-firstchild{
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  width: 200px;
  /* margin-bottom: 10px; */
}
.single-book-col {
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  /* text-align: center; */
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.single-book-col:last-child {
  border-right: none;
}
.single-book-col:first-child {
  /* flex: 2 1 1 1; */
  width: 600px;
}
.single-book-col > span {
  margin: 10px 20px;
}

.completed-booking {
  margin-bottom: 50px;
  font-size: 0.7rem;
  margin-top: 50px;
  /* width: 50vw; */
  font-size: 0.6rem;
}
.completed-booking table {
  border-collapse: separate; /* Make sure the table cells have their own borders */
  border-spacing: 2rem;
}
h1 {
  font-weight: bold;
}
.register-form-image {
  width: 40%;
  height: 100%;
}
.profile-image {
  margin-top: 3rem;
  background-color: red;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
}
.profile-form-navbar {
  /* width: 30%; */
  /* height: 150%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile-form-navbar > * {
  margin: 10px;
}
.profile-form-navbar-options > * {
  margin-top: 20px;
}
.profile-form-navbar-options {
  display: flex;
  flex-direction: column;
}
.profile-form-navbar-options .selected {
  background-color: orange;
  padding: 2rem 6rem;
  width: 100%;
  text-align: left;
}
/* .register-form-image > img{
    height: 100%;
    width: 100%;
  } */
.form {
  display: flex;
  flex-direction: column;

  /* overflow: hidden; */
  width: 100%;
}
.form-item {
  align-items: center;
  margin: 10px 0;
}
.right-sider {
  /* width: 70%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: nowrap;
  border-left: 1px solid grey;
  /* margin: 5rem 0; */
}
.right-sider > * {
  /* margin-left: 20px;
    margin-top: 10rem; */
}

.form-item {
  text-align: left;
}
.profile-form > * {
  margin: 50px 20px;
}
.email-div {
  text-align: left;
}
.password-div {
  text-align: left;
}
.button-div {
  text-align: center;
}
.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: large;
  /* padding: 1rem; */
}

input {
  padding: 10px;
  border-radius: 5px;
  border: 0.1rem solid black;
  /* width: 20rem; */
}

.submit-btn {
  /* position: relative; */
  top: 0px;
  width: 80%;
  /* padding: 10px;s */
  border-radius: 0.5rem;
  color: white;
}
.form-option-btn {
  text-align: start;
  padding: 1rem;
  width: 100%;
}
.form-option-btn.active {
  background-color: orange;
}

@media only screen and (max-width: 900px) {
  /*Tablets [601px -> 1200px]*/
  .bookings{
   width:200px

  }
  .Profile {
    text-align: center;
    min-height: 250vh;
    max-width: 99vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* position: relative; */
  }
  .profile-form-container {
    background-color: rgb(255, 255, 255);
    /* top: 40vh; */
    /* z-index: 100; */
    /* position: absolute; */
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    /* display: flex; */
    min-width: 13vw;
    /* min-height: 195vh; */
    font-size: 1.2rem;
  }
  /* .right-sider > * {
    margin: auto;
    margin-top: 5rem;
  } */
  /*  */
  .footer-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile-form-container {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    /* width: 90vw; */
    /* min-height: 85vh; */
    /* margin: 0; */
  }
  /* .right-sider {
    border-left: none;
    border-top: 1px gray solid;
    text-align: center;
  } */
  .profile-form-navbar-options {
    display: flex;
    flex-direction: row;
   
  }
  .form-item {
    text-align: start;
    width: 190%;
  }
  .form-option-btn {
    text-align: start;
    padding: 0px;
    width: 5rem;
    overflow-x: unset;
  }
  .form-option-btn.active {
    background-color: orange;
  }

  .profile-form-navbar-options > * {
    margin: 1rem;
  }
  .profile-form-image {
    display: none;
  }
}
