.Navbar{
    position:fixed;
  width: 100%;
    z-index: 1000;
    border-bottom: black 1px solid;
 
   
}
.logo {
    width: 195px;
    height: 150px;
    object-fit: fill;
    background: inherit;
    cursor: pointer;
}
/* #mynav{
    top: 20px;
    @media only screen and (max-width: 600px){
        top: 0px;
    }
} */
.yellow-bar{
    background-color: #fda71a;
    width: 100vw;
    position: fixed;
    top: 0px;
    @media only screen and (max-width: 600px){
        font-size: 8px;
        display: none;
    }
}
.main-navbar{
    background-color: #f8f9fa;
    /* min-height: 15vh; */
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    /* align-items: center; */
    font-size: 15px;

}
.navbuttons{
    @media only screen and (max-width: 600px){
        display: none;
    }
}
.auth-items-navbar{
    font-family: Poppins;
}
.item-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
.item-list li {
    /* margin-left: 2rem; */
    cursor: pointer;
    position: relative;
    padding: 0px 20px;

  }
  
  .item-list li::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  
  .item-list li.active{
    font-weight: bold;
    font-size: 120%;
  }
  .item-list li.active::after {
    /* background-color: orange; */
    background-color: #ed5826;
  }
.logo{
    height: 100%;
    width: 100px;
}
.main-items-navbar{

}
.main-items-navbar .item-list{
    list-style: none;
    display: flex;
    flex-direction: row;
}
.main-items-navbar .item-list li{
    margin: auto 10px;
}
.auth-items-navbar{
    
}
.auth-items-navbar a{
    margin: auto 10px;
}

@media only screen and (max-width: 900px){
    /*Tablets [601px -> 1200px]*/
    .Navbar{
        position: relative;
    }
    .main-navbar{
        /* height: 20vh; */
        display: flex;
        flex-direction: column;
        align-items: center;
        
        /* margin-bottom: 25rem; */
    
    }
    .main-items-navbar .item-list{
        flex-direction: column;
    }
    /* .logo{
        height: 100px;
        width: 100px;
    } */
}
@media only screen and (max-width: 600px){
	/*Big smartphones [426px -> 600px]*/
}
@media only screen and (max-width: 425px){
	/*Small smartphones [325px -> 425px]*/
}
.drawerAlignment {
                align-items: center !important;
                justify-content: center !important;
    }
