.Register{
  text-align: center;
  /* height: 80vh; */
  width: 50vw;
  /* max-width: 100vw; */
  display: flex;
  align-items: center;
  /* position: fixed; */
  /* overflow: hidden; */
}



.register-form-image{
  /* width: 40%; */
  height: 100%;
}
.register-form{
  /* margin: 50px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  
  
}
.register-form-image > img{
  height: 100%;
  width: 100%;
}
.email-div{
  text-align: left;
}
.password-div{
  text-align: left;
}
.button-div{
  text-align: center;
}
.close-button{
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: large;
  /* padding: 1rem; */
}
.submit-btn{
  /* position: relative; */
  top: 0px;
  width: 10%;
  padding: 10px;
  border-radius: .5rem;
  color: white;
  margin: 10px 40px;
  
}


.form {
  /* overflow: hidden; */
  width: 10%;
  text-align: left;
}
@media only screen and (max-width: 900px){
  .Register{
    height: 80vh;
    width: 80vw;
    /* max-width: 100vw; */
    display: flex;
    align-items: center;
    /* position: relative; */
    overflow: hidden;
  }
  .register-form-image{
    display: none;
  }

}